import React, { useEffect, useRef, useState } from 'react';
import LoadingSpinner from "./LoadingSpinner";
import {Text} from "./DeviceGraphics/Text";
import {Button} from "./DeviceGraphics/Button";
import {Slider} from "./DeviceGraphics/Slider";
import {Switch} from "./DeviceGraphics/Switch";
import {StatusDot} from "./DeviceGraphics/StatusDot";
import Popup from "./Popup";
import {TinkerIcon} from "./icons";

export const DeviceCell = ({ robotConnection, device, isTinkering }) => {
    const [state, setState] = useState(null);
    const [showTinkerPopup, setShowTinkerPopup] = useState(false);
    const initialSend = useRef(false);
    const initialProcess = useRef(false);

    useEffect(() => {
        if (!robotConnection.current || !device || initialSend.current) {
            return;
        }
        initialSend.current = true;
        robotConnection.current.connection.deviceListeners.set(device, setState);
        robotConnection.current.connection.getState(device).then((s) => {
            setupGraphics(s);
        });

    }, [device, robotConnection]);

    useEffect(() => {
        if (!robotConnection.current || state === null) {
            return;
        }
        if (initialProcess.current) {
            const stateString = {
                "state": JSON.stringify(state),
                "uuid": device
            };
            robotConnection.current.connection.updateDeviceState(stateString);
            return;
        }
        initialProcess.current = true;
    }, [state]);

    function setupGraphics(starting_state) {
        let parsedObject = JSON.parse(starting_state);
        for (let key in parsedObject) {
            parsedObject[key] = JSON.parse(parsedObject[key]);
        }
        setState(parsedObject);
    }

    function updateStateFor(uuid, newState) {
        setState(prevState => ({
            ...prevState,
            [uuid]: newState,
        }));
    }

    const cellStyle = {
        backgroundColor: '#3a3d41',
        color: '#61dafb',
        borderRadius: '10px',
        padding: '20px',
        margin: '10px',
        width: '150px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.2s ease-in-out',
        textAlign: 'center',
        fontFamily: 'system-ui',
        display: 'inline-table',
        cursor: isTinkering ? 'inherit' : 'default',
    };

    const graphics = state
        ? Object.entries(state).map(([uuid, value]) => {
            switch (value.type) {
                case "HeaderText":
                    return <Text textType={"HeaderText"} state={value} key={uuid} />;
                case 'BodyText':
                    return <Text textType={"BodyText"} state={value} key={uuid} />;
                case 'SubText':
                    return <Text textType={"SubText"} state={value} key={uuid} />;
                case 'Button':
                    return <Button state={value} updateState={updateStateFor} uuid={uuid} isTinkering={isTinkering} key={uuid} />;
                case 'Slider':
                    return <Slider state={value} updateState={updateStateFor} uuid={uuid} isTinkering={isTinkering} key={uuid} />;
                case 'Switch':
                    return <Switch state={value} updateState={updateStateFor} uuid={uuid} isTinkering={isTinkering} key={uuid} />;
                case 'StatusDot':
                    return <StatusDot state={value} key={uuid} />;
                case 'HStack':
                    console.error("TODO!");
                    return null;
                default:
                    console.error("Unknown component type: " + value.type);
                    return null;
            }
        })
        : [];

    return (
        <>
            <div style={cellStyle} onMouseDown={() => {
                if (isTinkering) return;
                setShowTinkerPopup(true);
            }}>
                {state === null ? (
                    <LoadingSpinner dimension={30} />
                ) : (
                    <div>
                        {graphics.map((graphic, index) => (
                            <div style={{ marginBottom: '10px' }} key={index}>{graphic}</div>
                        ))}
                    </div>
                )}
            </div>
            <Popup shown={showTinkerPopup} onClose={() => { setShowTinkerPopup(false); }} dismissible={true}>
                <p>Enable tinker mode to modify devices.</p>
                <br />
                <p>Use the <span style={{ paddingLeft: '5px', paddingRight: '5px' }}><TinkerIcon /></span> icon in the top right.</p>
            </Popup>
        </>
    );
};
