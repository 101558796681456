import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { TestIcon, StopIcon, TinkerIcon, ReloadIcon, RunIcon } from "../icons";
import { BLEConnection } from "./BLEConnection";
import { TCPConnection } from "./TCPConnection";
import {NetworkConnection} from "./NetworkConnection";
import Popup from "../Popup";
import GlowField from "../GlowField";
import GlowButton from "../GlowButton";

const RobotConnection = forwardRef(({
                                        reloadPressed,
                                        connected,
                                        setConnected,
                                    }, ref) => {

    const [connection, setConnection] = useState(() => new NetworkConnection());
    const [statusVisible, setStatusVisible] = useState(true);
    const [connecting, setConnecting] = useState(false);
    const [statusDotColor, setStatusDotColor] = useState('red');

    const [popupShown, setPopupShown] = useState(false);
    const [TCPFormShown, setTCPFormShown] = useState(false);
    const [ip, setIp] = useState('');
    const [BLEDisabled, setBLEDisabled] = useState(false);
    const [TCPDisabled, setTCPDisabled] = useState(false);
    const [discoveryText, setDiscoveryText] = useState('Discover IP with BLE');

    const [reloadDisabled, setReloadDisabled] = useState(false);
    const [runDisabled, setRunDisabled] = useState(false);
    const [stopDisabled, setStopDisabled] = useState(true);
    const [tinkerDisabled, setTinkerDisabled] = useState(false);
    const [testDisabled, setTestDisabled] = useState(true);

    const [isRunning, setIsRunning] = useState(false);
    const [isTinkering, setIsTinkering] = useState(false);
    const [isTesting, setIsTesting] = useState(false);

    const [subscribers, setSubscribers] = useState({});

    useEffect(() => {
        if (!connection.isRealConnection()) return;
        connection.setCallbacks({
            onConnectionChange: (status) => {
                const isConnected = status === "connected";
                const isConnecting = status === "connecting";

                setConnected(isConnected);
                setConnecting(isConnecting);

                // Only notify subscribers for connected/disconnected states
                if (!isConnecting) {
                    Object.values(subscribers).forEach(subscriber =>
                        subscriber('is-connected', isConnected)
                    );
                }
            },
            onLogMessage: (type, message) => {
                Object.values(subscribers).forEach(subscriber => {
                    subscriber(type, message);
                });
            }
        });

        return () => {
            if (connection.isConnected()) {
                connection.disconnect();
            }
        };
    }, [connection, setConnected]);

    useEffect(() => {
        if (connected) {
            setStatusDotColor('green');
        } else if (connecting) {
            setStatusDotColor('orange');
        } else {
            setStatusDotColor('red');
        }
    }, [connected, connecting]);

    useImperativeHandle(ref, () => ({
        flashStatusDot,
        addSubscriber,
        connection,
        connected,
        setConnected,
        isRunning,
    }));

    function addSubscriber(id, subscriber) {
        setSubscribers(prev => ({ ...prev, [id]: subscriber }));
    }

    function open_connection_popup() {
        setPopupShown(true);
    }

    async function discover_ip() {
        setDiscoveryText("Connecting...");
        connect_with_bluetooth().then(async (conn) => {
            setIp(await conn.get_ip());
            conn.disconnect();
            setConnection(new NetworkConnection());
        }).finally(() => {
            setDiscoveryText("Discover IP with BLE");
            setConnecting(false);
        })
    }

    function reset_connection_states() {
        setPopupShown(false);
        setTCPFormShown(false);
        setConnecting(false);
        setBLEDisabled(false);
        setTCPDisabled(false);
    }

    async function connect_with_bluetooth() {
        setConnecting(true);
        let conn = new BLEConnection();
        await conn.connect()
        setConnection(conn);
    }

    async function connect_with_tcp() {
        try {
            let conn = await new TCPConnection(ip).connect();
            setConnection(conn);
            setConnected(true);
            reset_connection_states();
        } catch (error) {
            console.error("TCP connection error:", error);
        }
    }

    function runPressed() {
        setRunDisabled(true);
        setTestDisabled(true);
        setTinkerDisabled(true);
        setStopDisabled(false);
        setIsRunning(true);
        Object.values(subscribers).forEach(subscriber => {
            subscriber('is-running', true);
        });
        connection.executeTarget();
    }

    function stopPressed() {
        connection.stopExecution().then(() => {
            let currentMode;
            if (isRunning) {
                currentMode = 'is-running';
                setIsRunning(false);
            }
            else if (isTesting) {
                currentMode = 'is-testing';
                setIsTesting(false);
            }
            else if (isTinkering) {
                currentMode = 'is-tinkering'
                setIsTinkering(false);
            }
            setStopDisabled(true);
            setRunDisabled(false);
            setTestDisabled(false);
            setTinkerDisabled(false);
            Object.values(subscribers).forEach(subscriber => {
                subscriber(currentMode, false);
            });
        });
    }

    function testPressed() {
        console.error("Not implemented.");
    }

    function tinkerPressed() {
        setRunDisabled(true);
        setTestDisabled(true);
        setTinkerDisabled(true);
        setStopDisabled(false);
        setIsTinkering(true);
        Object.values(subscribers).forEach(subscriber => {
            subscriber('is-tinkering', true);
        });
    }

    const flashStatusDot = () => {
        const interval = 150;
        for (let i = 0; i < 3; i++) {
            setTimeout(() => setStatusVisible(false), i * 2 * interval);
            setTimeout(() => setStatusVisible(true), (i * 2 + 1) * interval);
        }
    };


    return (<>
            <div style={styles.container}>
                <div style={{
                    backgroundColor: statusDotColor,
                    ...styles.statusDot
                }} hidden={!statusVisible}/>
                <div style={{
                    backgroundColor: styles.container.backgroundColor,
                    ...styles.statusDot
                }} hidden={statusVisible}/>

                <div style={styles.textContainer}>
                    <div style={styles.primaryText}>
                        {connected ? connection.name : "Disconnected"}
                    </div>
                </div>
                {!connected ? (
                    <div style={styles.connectionContainer}>
                        <button
                            style={styles.connectButton(connecting)}
                            onClick={open_connection_popup}
                            disabled={connecting}
                        >
                            {connecting ? "Connecting..." : "Connect"}
                        </button>
                    </div>
                ) : (
                    <div style={styles.controlPanel}>
                        <button onClick={reloadPressed} disabled={reloadDisabled} style={styles.button} title="Reload">
                            <ReloadIcon disabled={reloadDisabled}/>
                        </button>
                        <button onClick={testPressed} disabled={testDisabled} style={styles.button} title="Test">
                            <TestIcon disabled={testDisabled}/>
                        </button>
                        <button onClick={tinkerPressed} disabled={tinkerDisabled} style={styles.button} title="Tinker">
                            <TinkerIcon disabled={tinkerDisabled}/>
                        </button>
                        <button onClick={stopPressed} disabled={stopDisabled} style={styles.button} title="Stop">
                            <StopIcon disabled={stopDisabled}/>
                        </button>
                        <button onClick={runPressed} disabled={runDisabled} style={styles.button} title="Run">
                            <RunIcon disabled={runDisabled}/>
                        </button>
                    </div>
                )}
            </div>
            <Popup shown={popupShown} onClose={() => {
                setPopupShown(false)
            }} dismissible={true}>

                <button
                    style={{...styles.connectButton(BLEDisabled), width: '80%'}}
                    onClick={async () => {
                        setTCPDisabled(true);
                        setBLEDisabled(false);
                        setTCPFormShown(false);
                        connect_with_bluetooth().then(() => {
                            setConnected(true);
                            reset_connection_states();
                        }).catch(() => {
                            setConnected(false);
                            setConnecting(false);
                            setTCPDisabled(false);
                        })
                    }}
                >
                    Connect with BLE
                </button>
                <div style={{marginBottom: '10px'}}></div>
                <button
                    style={{...styles.connectButton(TCPDisabled), width: '80%'}}
                    onClick={() => {
                        setTCPDisabled(false);
                        setTCPFormShown(true);
                        setBLEDisabled(true);
                    }}
                >
                    Connect with TCP
                </button>
                {TCPFormShown && (
                    <div style={{marginTop: '20px'}}>
                        <GlowField value={ip} onChange={setIp} placeholder={"IP Address"} ></GlowField>
                        <GlowButton text={discoveryText} size={"small"} primary={false} onClick={() => {
                            discover_ip().catch(() => {

                            });
                        }}></GlowButton>
                        {ip && (
                            <GlowButton text={"Connect to " + ip} onClick={connect_with_tcp} size={"10px"}></GlowButton>
                        )}

                    </div>
                )}

            </Popup>
        </>

    );
});

const styles = {
    container: {
        flex: 1,
        padding: '10px',
        backgroundColor: 'rgba(50, 50, 50, 0.8)',
        border: '1px solid rgba(50, 50, 50, 0.8)',
        borderRadius: '15px',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
        transition: 'all 0.3s ease-in-out',
    },
    connectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    statusDot: {
        marginLeft: 'auto',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    },
    textContainer: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    primaryText: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    connectButton: (disabled) => ({
        padding: '10px 20px',
        fontSize: '16px',
        cursor: disabled ? 'default' : 'pointer',
        backgroundColor: disabled ? '#78a4af' : '#61dafb',
        color: '#282c34',
        border: 'none',
        borderRadius: '10px',
        transition: 'background-color 0.2s ease, transform 0.2s ease',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        outline: 'none',
    }),
    controlPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '10px',
    },
    button: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '0 10px',
        outline: 'none',
        transition: 'transform 0.1s ease',
    },
    buttonPressed: {
        transform: 'scale(0.9)',
    },
};

export default RobotConnection;
