import React from "react";
import './styles/base.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './routes/auth';
import Home from './routes/home';
import Console from "./routes/console/console";




function App() {
  return (
      <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/console" element={<Console />} />
            <Route path="*" element={<h1>404 not found</h1>}></Route>
        </Routes>
      </Router>
  );
}

export default App;
