import React from 'react';

export const Button = ({ state, updateState, uuid, isTinkering }) => {
    const style = {
        color: state.text_color,
        backgroundColor: state.background_color,
        border: "none",
        borderRadius: "8px",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: isTinkering ? "pointer": "default",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.1s ease, box-shadow 0.1s ease",
    };

    const handleClick = () => {
        if (isTinkering) {
            let s = { ...state, pressed: true };
            updateState(uuid, s);
        }
    };


    return (
        <button
            style={style}
            onClick={handleClick}
            onMouseDown={(e) => {
                if (isTinkering) {
                    e.target.style.transform = "scale(0.98)";
                    e.target.style.boxShadow = "0px 2px 5px rgba(0, 0, 0, 0.2)";
                }
            }}
            onMouseUp={(e) => {
                if (isTinkering) {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.2)";
                }
            }}
            onMouseLeave={(e) => {
                if (isTinkering) {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.2)";
                }
            }}
        >
            {state.text}
        </button>
    );
};
