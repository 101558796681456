import React, { useState, useEffect } from 'react';
import { DeviceCell } from './DeviceCell';

export const DeviceCellContainer = ({ robotConnection, devices }) => {

    const [isTinkering, setIsTinkering] = useState(false);


    useEffect(() => {
        if (!robotConnection.current) return;
        robotConnection.current.addSubscriber('device-cell-container', subscriber);
    }, [robotConnection.current]);

    const subscriber = (type, status) => {
        if (type === 'is-tinkering') {
            setIsTinkering(status);
        }
    };

    const containerStyle = {
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: '20px',
        padding: '20px',
        width: '90%',
        margin: 'auto',
        backgroundColor: 'rgba(50, 50, 50, 0.8)',
        borderRadius: '15px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    };

    return (
        <div style={containerStyle}>
            {devices.map((device, index) => (
                <DeviceCell
                    robotConnection={robotConnection}
                    device={device}
                    isTinkering={isTinkering}
                    key={index}
                />
            ))}
        </div>
    );
};
