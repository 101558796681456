import React, { useState } from "react";

export const Slider = ({ state, updateState, uuid, isTinkering }) => {
    const [currentValue, setCurrentValue] = useState(state.value);

    const handleInputChange = (event) => {
        setCurrentValue(parseFloat(event.target.value));
    };

    const handleMouseUp = () => {
        updateState(uuid, { ...state, value: currentValue });
    };

    const sliderStyle = {
        width: "100%",
        height: "6px",
        borderRadius: "4px",
        backgroundColor: state.color,
        position: "relative",
        outline: "none",
        appearance: "none",
        cursor: isTinkering ? "pointer" : "default",
    };

    const labelStyle = {
        fontSize: "12px",
        color: "lightgray",
    };

    return (
        <div style={{ width: "300px", textAlign: "center", position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <span style={labelStyle}>{state.min_value}</span>
                <span style={labelStyle}>{state.max_value}</span>
            </div>
            <div style={{ position: "relative", height: "6px", marginBottom: "20px" }}>
                <input
                    type="range"
                    disabled={!isTinkering}
                    min={state.min_value}
                    max={state.max_value}
                    step={state.step}
                    value={currentValue}
                    onChange={handleInputChange}
                    onMouseUp={handleMouseUp}
                    onTouchEnd={handleMouseUp}
                    style={{ ...sliderStyle }}
                />
            </div>
            <div style={{ fontSize: "12px", color: "lightgray" }}>{currentValue}</div>
        </div>
    );
};