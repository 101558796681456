import React from 'react';
import GlowButton from "../ReactComponents/GlowButton";
const Home = () => {

    return (
        <>
            <h1>Cyber•Station</h1>
            <h2>The center of unified robotics development.</h2>
            <GlowButton text={"Login"} onClick={() => window.location = '/auth'}></GlowButton>
            <GlowButton text={"Sign up"} onClick={() => window.location = '/auth'}></GlowButton>
            <footer>
                <p>(c) Cyberonics 2024, all rights reserved.</p>
            </footer>
        </>
    );
};

export default Home;
