import React, {
    useState,
    useRef,
    useImperativeHandle,
    forwardRef,
    useEffect,
} from 'react';

// ForwardRef is used to expose methods to parent ReactComponents
const Terminal = forwardRef(({ tab, robotConnection, selectedProject }, ref) => {

    const [output, setOutput] = useState([]);
    const [executionLog, setExecutionLog] = useState([]);
    const [command, setCommand] = useState('');

    const [projectDirectory, setProjectDirectory] = useState("");
    const [currentDirectory, setCurrentDirectory] = useState(null);

    // Refs for DOM elements to handle scrolling and focusing
    const terminalEndRef = useRef(null);
    const inputRef = useRef(null);

    const [deviceName, setDeviceName] = useState('');

    // State for resizing
    const [isResizing, setIsResizing] = useState(false);
    const [resizeDirection, setResizeDirection] = useState(null); // 'top', 'left', or 'right'
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [startSize, setStartSize] = useState({ width: 600, height: 300 });
    const [width, setWidth] = useState(600); // Initial width in px
    const [height, setHeight] = useState(300); // Initial height in px

    const [selectedTab, setSelectedTab] = useState(tab);

    const minWidth = 300;
    const maxWidth = 1500;
    const minHeight = 200;
    const maxHeight = 600;

    // Command History State
    const [commandHistory, setCommandHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1); // -1 indicates no history navigation

    const stdout = (msg) => {
        setOutput((prev) => [...prev, { type: 'stdout', msg }]);
    };

    const stderr = (msg) => {
        setOutput((prev) => [...prev, { type: 'stderr', msg }]);
    };

    // Scroll to the bottom whenever output or executionLog changes
    useEffect(() => {
        if (terminalEndRef.current) {
            terminalEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [output, executionLog, selectedTab]);

    useEffect(() => {
        if (!robotConnection.current) return;

        const subscriber = (type, status) => {
            if (type === 'is-running') {
                setSelectedTab(status ? 'output' : 'command');
            }
            else if (type === "stdout" || type === "stderr") {
                setExecutionLog((prevLog) => [...prevLog, { type: type, msg: status }]);
            }
            else if (type === "is-connected") {
                if (status) {
                    setDeviceName(robotConnection.current.connection.name || '');
                }
                else {
                    if (inputRef?.current) {
                        inputRef.current.blur();
                    }
                    setOutput([]);
                    console.log("!")
                    setExecutionLog([]);
                    setCommand("");
                    setProjectDirectory("");
                    setCurrentDirectory(null);
                }
            }
        };
        robotConnection.current.addSubscriber('terminal', subscriber);

        setSelectedTab(robotConnection.current.isRunning ? 'output' : 'command');
    }, [robotConnection]);

    // Handle user input when Enter is pressed
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const trimmedCommand = command.trim();
            if (trimmedCommand !== '' && robotConnection.current?.connected) {
                setOutput((prev) => [...prev, { type: 'command', msg: trimmedCommand }]);

                // Update command history
                setCommandHistory((prevHistory) => [...prevHistory, trimmedCommand]);
                setHistoryIndex(-1); // Reset history navigation

                if (trimmedCommand.toLowerCase() === 'clear') {
                    // Clear the terminal
                    setOutput([]);
                    console.log("!")
                    setExecutionLog([]);
                } else if (trimmedCommand.startsWith("cd")) {
                    changeDirectory(trimmedCommand.substring(3)); // Shave off "cd "
                }
                else {
                    if (currentDirectory === null) {
                        robotConnection.current.connection.getProjectDirectory().then((result) => {
                            setCurrentDirectory(result);
                            setProjectDirectory(result);
                            const command_with_path = `cd "${result}" && ${trimmedCommand}`;
                            sendCommand(command_with_path);
                        }).catch((error) => {
                            console.error(error)
                            stderr(error);
                        })
                    }
                    else {
                        const command_with_path = `cd "${currentDirectory}" && ${trimmedCommand}`;
                        sendCommand(command_with_path);
                    }
                }

                setCommand('');
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (commandHistory.length === 0) return;

            setHistoryIndex((prevIndex) => {
                const newIndex = prevIndex + 1;
                if (newIndex >= commandHistory.length) {
                    return commandHistory.length - 1;
                }
                setCommand(commandHistory[commandHistory.length - 1 - newIndex]);
                return newIndex;
            });
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (commandHistory.length === 0) return;

            setHistoryIndex((prevIndex) => {
                const newIndex = prevIndex - 1;
                if (newIndex < 0) {
                    setCommand('');
                    return -1;
                }
                setCommand(commandHistory[commandHistory.length - 1 - newIndex]);
                return newIndex;
            });
        }
    };

    function sendCommand(command) {
        robotConnection.current.connection.executeCommand(command).then((result) => {
            stdout(result);
        }).catch((error) => {
            stderr(error);
        });
    }

    function changeDirectory(path) {
        // If path is empty, reset to default directory
        if (!path || path.trim() === '') {
            setCurrentDirectory(projectDirectory);
            return;
        }

        let targetPath = path.startsWith('/') ? path : `${currentDirectory}/${path}`;

        // Normalize path (to handle .. and .)
        targetPath = normalizePath(targetPath);

        if (robotConnection.current?.connected) {
            robotConnection.current.connection.executeCommand(`cd ${targetPath}`).then((result) => {
                setCurrentDirectory(targetPath);
                stdout(result);
            }).catch((error) => {
                stderr(error);
            })
        }
        else {
            stderr("Not connected");
        }
    }

    function normalizePath(path) {
        const parts = path.split('/').filter(part => part !== '.' && part !== '');
        const stack = [];

        for (const part of parts) {
            if (part === '..') {
                stack.pop();
            } else {
                stack.push(part);
            }
        }

        return '/' + stack.join('/');
    }

    function handleTerminalClick() {
        if (!robotConnection.current.connected) {
            robotConnection.current.flashStatusDot();
        }
        else if (selectedTab === 'command' && inputRef.current) {
            inputRef.current.focus();
        }
    }

    // Handle resizing
    const handleResizeStart = (e, direction) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent triggering parent onClick
        setResizeDirection(direction);
        setIsResizing(true);
        setStartPos({ x: e.clientX, y: e.clientY });
        setStartSize({ width, height });
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isResizing) return;

            const deltaX = e.clientX - startPos.x;
            const deltaY = e.clientY - startPos.y;

            if (resizeDirection === 'top') {
                // Dragging the top resizer affects height
                let newHeight = startSize.height - deltaY;
                newHeight = Math.max(minHeight, Math.min(maxHeight, newHeight));
                setHeight(newHeight);
            }

            if (resizeDirection === 'right') {
                // Dragging the right resizer affects width
                let newWidth = startSize.width + deltaX;
                newWidth = Math.max(minWidth, Math.min(maxWidth, newWidth));
                setWidth(newWidth);
            }

            if (resizeDirection === 'left') {
                // Dragging the left resizer affects width
                let newWidth = startSize.width - deltaX;
                newWidth = Math.max(minWidth, Math.min(maxWidth, newWidth));
                setWidth(newWidth);
            }
        };

        const handleMouseUp = () => {
            if (isResizing) {
                setIsResizing(false);
                setResizeDirection(null);
            }
        };

        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, resizeDirection, startPos, startSize, width, height]);

    return (
        <div
            style={{
                ...styles.terminalContainer,
                width: `${width}px`,
                height: `${height}px`,
            }}
            onClick={handleTerminalClick}
        >
            {/* Resizer for vertical resizing (top edge) */}
            <div
                style={styles.topResizer}
                onMouseDown={(e) => handleResizeStart(e, 'top')}
            />
            {/* Resizer for horizontal resizing (left edge) */}
            <div
                style={styles.leftResizer}
                onMouseDown={(e) => handleResizeStart(e, 'left')}
            />
            {/* Resizer for horizontal resizing (right edge) */}
            <div
                style={styles.rightResizer}
                onMouseDown={(e) => handleResizeStart(e, 'right')}
            />
            <link href="https://fonts.googleapis.com/css2?family=Fira+Code&display=swap" rel="stylesheet" />
            <div
                style={styles.terminal}
                className="terminal-content" // Apply class for scrollbar hiding
            >
                {selectedTab === 'command' && (
                    <div>
                        {/* Display all output messages */}
                        {output.map((item, index) => {
                            if (item.type === 'stdout') {
                                return (
                                    <div key={index} style={styles.stdout}>
                                        {item.msg}
                                    </div>
                                );
                            } else if (item.type === 'stderr') {
                                return (
                                    <div key={index} style={styles.stderr}>
                                        {item.msg}
                                    </div>
                                );
                            } else if (item.type === 'command') {
                                return (
                                    <div key={index} style={styles.command}>
                                        <span style={styles.prompt}>{deviceName} $ </span>
                                        {item.msg}
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                        {/* Command input line */}
                        <div style={styles.inputLine}>
                            <span style={styles.prompt}>{robotConnection.current?.connected ? `${deviceName} $` : `$`} </span>
                            <input
                                ref={inputRef}
                                type="text"
                                value={command}
                                onChange={(e) => setCommand(e.target.value)}
                                onKeyDown={handleKeyDown}
                                onFocus={() => {
                                    if (!robotConnection.current.connected) inputRef.current.blur();
                                }}
                                style={styles.input}
                                autoComplete="off"
                                spellCheck="false"
                            />
                        </div>
                    </div>
                )}
                {selectedTab === 'output' && (
                    <div>
                        {executionLog.map((item, index) => {
                            if (item.type === 'stdout') {
                                return (
                                    <div key={index} style={styles.stdout}>
                                        {item.msg}
                                    </div>
                                );
                            } else if (item.type === 'stderr') {
                                return (
                                    <div key={index} style={styles.stderr}>
                                        {item.msg}
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}

                    </div>
                )}
                <div ref={terminalEndRef} />
            </div>
        </div>
    );
});

const styles = {
    terminalContainer: {
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'linear-gradient(135deg, #1e1e1e, #2c2c2c)',
        color: '#e0e0e0',
        fontFamily: '"Fira Code", monospace',
        overflow: 'hidden',
        padding: '15px',
        boxSizing: 'border-box',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
        zIndex: 0,
        cursor: 'text',
    },
    terminal: {
        height: '100%',
        width: '100%',
        position: 'relative',
        whiteSpace: 'pre-wrap',
        overflowY: 'auto',
        paddingRight: '10px',
    },
    stdout: {
        color: '#e0e0e0',
        margin: '2px 0',
        lineHeight: '1.4',
    },
    stderr: {
        color: '#ff6b6b',
        margin: '2px 0',
        lineHeight: '1.4',
    },
    command: {
        color: '#a8ff60',
        margin: '2px 0',
        lineHeight: '1.4',
    },
    inputLine: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '2px',
        height: '24px',
    },
    prompt: {
        color: '#a8ff60',
        marginRight: '5px',
    },
    input: {
        backgroundColor: 'transparent',
        color: '#e0e0e0',
        border: 'none',
        outline: 'none',
        flex: 1,
        fontFamily: '"Fira Code", monospace',
        fontSize: '1em',
        padding: '2px',
        caretColor: '#a8ff60',
    },
    topResizer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '10px',
        cursor: 'row-resize',
        zIndex: 1,
    },
    leftResizer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '10px',
        height: '100%',
        cursor: 'col-resize',
        zIndex: 2,
    },
    rightResizer: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '10px',
        height: '100%',
        cursor: 'col-resize',
        zIndex: 1001,
    }
};

export default Terminal;
