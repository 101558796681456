import { NetworkConnection } from "./NetworkConnection";

export class TCPConnection extends NetworkConnection {
    constructor(address) {
        super();
        if (address.includes("://")) {
            address = address.substring(address.indexOf("://") + 3);
        }
        if (!address.includes(":")) {
            address = address + ":5467"
        }
        this.address = address;
        this.ws = null;
        this.onConnectionChange = null;
        this.onLogMessage = null;
        this.pendingRequests = new Map();
        this.requestCounter = 0;
        this.heartbeatInterval = null;
        this.lastHeartbeat = null;
        this.deviceListeners = new Map();
    }

    setCallbacks(callbacks) {
        this.onConnectionChange = callbacks.onConnectionChange;
        this.onLogMessage = callbacks.onLogMessage;
    }

    async connect() {
        try {
            void this.onConnectionChange?.('connecting');
            this.ws = new WebSocket(`ws://${this.address}/ws`);

            return new Promise((resolve, reject) => {
                this.ws.onopen = async () => {
                    try {
                        this.setupMessageHandler();
                        this.startHeartbeat();
                        this.name = await this.sendRequest("name");
                        void this.onConnectionChange?.('connected');
                        resolve(this);
                    } catch (error) {
                        void this.onConnectionChange?.('disconnected');
                        reject(error);
                    }
                };

                this.ws.onerror = (error) => {
                    void this.onConnectionChange?.('disconnected');
                    reject(error);
                };

                this.ws.onclose = () => {
                    this.stopHeartbeat();
                    void this.onConnectionChange?.('disconnected');
                };
            });
        } catch (error) {
            void this.onConnectionChange?.('disconnected');
            return Promise.reject(error);
        }
    }

    setupMessageHandler() {
        this.ws.onmessage = (event) => {
            const response = JSON.parse(event.data);
            for (const device in response["state"]) {
                for (const graphic in response["state"][device]) {
                    response["state"][device][graphic] = JSON.parse(response["state"][device][graphic]);
                }
            }

            // Handle heartbeat responses
            if (response.type === 'heartbeat') {
                this.lastHeartbeat = Date.now();
                return;
            }

            // Handle device updates
            if (response.type === 'device_update') {
                for (const uuid in response.state) {
                    let listener = this.deviceListeners.get(uuid);
                    if (listener) {
                        listener(response.state[uuid]);
                    }
                }
            }

            // Handle logging messages
            if (response.type === 'log') {
                void this.onLogMessage?.(response.log_type, response.message);
            }

            const request = this.pendingRequests.get(response.id);
            if (request) {
                this.pendingRequests.delete(response.id);
                if (response.success) {
                    request.resolve(response.response);
                } else {
                    request.reject(response.response);
                }
            }

        };
    }

    startHeartbeat() {
        this.lastHeartbeat = Date.now();
        this.heartbeatInterval = setInterval(() => {
            if ((Date.now() - this.lastHeartbeat) / 1000 > 2.5) {
                this.disconnect();
                this.stopHeartbeat();
            } else {
                this.sendRequest('heartbeat').catch(() => {
                    // If heartbeat fails, we'll eventually disconnect due to timeout
                });
            }
        }, 1000);
    }

    stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
        this.lastHeartbeat = null;
    }

    async sendRequest(endpoint, data = null) {
        if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
            throw new Error("Not connected");
        }

        const requestId = this.requestCounter++;
        const request = {
            id: requestId,
            endpoint: endpoint,
            data: data
        };

        return new Promise((resolve, reject) => {
            this.pendingRequests.set(requestId, { resolve, reject });
            this.ws.send(JSON.stringify(request));
        });
    }

    disconnect() {
        this.stopHeartbeat();
        if (this.ws) {
            this.ws.close();
        }
        this.ws = null;
        void this.onConnectionChange?.('disconnected');
    }

    async listDevices() {
        let response = await this.sendRequest('list-devices');
        let devices = response.split(",") || [];
        return devices.length === 0 || devices[0].length === 0 ? [] : devices;
    }

    async executeCommand(command) {
        return this.sendRequest('execute-command', { command });
    }

    async updateDeviceState(newState) {
        const state = JSON.parse(newState.state);
        const uuid = newState.uuid;
        return this.sendRequest('set-state', {state: state, uuid: uuid});
    }

    async switchProject(projectId) {
        return this.sendRequest('switch-project', { project_id: projectId });
    }

    async listProjects() {
        return this.sendRequest('list-projects');
    }

    async getProject() {
        return this.sendRequest('get-project');
    }

    async getBranch() {
        return this.sendRequest('get-branch');
    }

    async getBranches() {
        return this.sendRequest('get-branches');
    }

    async getCommitHash() {
        return this.sendRequest('get-commit-hash');
    }

    async getTarget() {
        return this.sendRequest('get-target');
    }

    async getTargets() {
        return this.sendRequest('get-targets');
    }

    async getProjectDirectory() {
        return this.sendRequest('get-project-directory');
    }

    async switchBranch(branchName) {
        return this.sendRequest('switch-branch', { branch_name: branchName });
    }

    async changeTarget(targetName) {
        return this.sendRequest('change-target', { target_name: targetName });
    }

    async pullChanges() {
        return this.sendRequest('pull-changes');
    }

    async installProject(projectId, url, token = null) {
        return this.sendRequest('install-project', {
            project_id: projectId,
            url: url,
            token: token
        });
    }

    async executeTarget() {
        return this.sendRequest('execute-target');
    }

    async tinker() {
        return this.sendRequest('tinker');
    }

    async stopExecution() {
        return this.sendRequest('stop-execution');
    }

    async getState(deviceId) {
        return this.sendRequest('get-state', { device_id: deviceId });
    }

    async getStates() {
        return this.sendRequest('get-states');
    }

    isConnected() {
        return this.ws?.readyState === WebSocket.OPEN;
    }

    isRealConnection() {
        return true;
    }
}