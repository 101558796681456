import React, { useState, useEffect } from "react";

export const StatusDot = ({ state }) => {
    const [visibility, setVisibility] = useState("visible");

    useEffect(() => {
        if (state?.flash) {
            flash();
        }
    }, [state]);

    function flash() {
        let i = 0;
        const timer = setInterval(() => {
            if (i >= 6) {
                clearInterval(timer);
                setVisibility("visible");
                return;
            }
            setVisibility(i % 2 === 0 ? "visible" : "hidden");
            i++;
        }, 200);
    }

    const dotStyle = {
        width: "15px",
        height: "15px",
        backgroundColor: state.color,
        borderRadius: "50%",
        visibility: visibility,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        position: 'relative',
    };

    return (
        <div style={{ textAlign: state.alignment || 'center' }}>
            <div style={dotStyle}></div>
        </div>
    );
};
