import React from "react";

export const Switch = ({ state, updateState, uuid, isTinkering }) => {
    const toggleSwitch = () => {
        if (isTinkering) {
            updateState(uuid, { ...state, value: !state.value });
        }
    };

    const switchStyle = {
        width: "50px",
        height: "26px",
        borderRadius: "13px",
        backgroundColor: state.value ? state.on_color : state.off_color,
        position: "relative",
        cursor: isTinkering ? "pointer": "default",
        transition: "background-color 0.3s ease",
    };

    const knobStyle = {
        width: "22px",
        height: "22px",
        backgroundColor: "#ffffff",
        borderRadius: "50%",
        position: "absolute",
        top: "2px",
        left: state.value ? "26px" : "2px",
        transition: "left 0.3s ease",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        cursor: isTinkering ? "pointer": "default",
    };

    return (
        <div style={switchStyle} onClick={toggleSwitch}>
            <div style={knobStyle} />
        </div>
    );
};
