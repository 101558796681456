import React from 'react';

export const Text =  ({ textType, state }) => {
    var textSize;
    switch (textType) {
        case "HeaderText":
            textSize = 24;
            break;
        case "BodyText":
            textSize = 18;
            break;
        case "SubText":
            textSize = 12;
            break;
        default:
            throw Error(`Unknown text type ${textType}`);
    }
    const style = {
        textAlign: state.alignment,
        color: state.color,
        fontWeight: state.bold ? 'bold' : 'normal',
        fontStyle: state.italic ? 'italic' : 'normal',
        fontSize: textSize,

    }
    return (
        <p style={style}>{state.text}</p>
    )
}