    import React, {forwardRef, useEffect, useRef, useState} from 'react';
    import {DropdownNavCell, NavBar} from "../../ReactComponents/NavBar";
    import {getAuth, onAuthStateChanged, signOut} from 'firebase/auth';
    import RobotConnection from "../../ReactComponents/Connection/RobotConnection";
    import {collection, getFirestore, getDocs, getDoc, doc, query, where} from "firebase/firestore";
    import NewProject from "../../ReactComponents/NewProject";
    import Popup from "../../ReactComponents/Popup";
    import GlowButton from "../../ReactComponents/GlowButton";

    const db = getFirestore()
    const auth = getAuth()

    async function getProjects() {
        const org = auth.currentUser.email.split("@")[1]
        const projectsCollection = collection(db, "projects");
        const q = query(projectsCollection, where("organization", "==", org))
        const projectsSnapshot = await getDocs(q);
        return projectsSnapshot.docs.map(doc => ({
            id: doc.id,
            label: doc.data().name,
            ...doc.data()
        }));
    }


    const ConsoleNavBar = forwardRef(({ user, robotConnection, connected, setConnected, selectedProject, setSelectedProject, setDevices }, ref) => {

        const [projects, setProjects] = useState([]);
        const selectedProjectId = useRef(null);
        const [branches, setBranches] = useState([]);
        const [selectedBranch, setSelectedBranch] = useState("Not connected");
        const [selectedCommit, setSelectedCommit] = useState("")

        const [targets, setTargets] = useState([]);
        const [selectedTarget, setSelectedTarget] = useState("Not connected");

        const [disableMenus, setDisableMenus] = useState(false);

        const [showNewProject, setShowNewProject] = useState(false);
        const [showInstallProject, setShowInstallProject] = useState(false);

        const [showPopupMessage, setShowPopupMessage] = useState(false);
        const [popupMessage, setPopupMessage] = useState("");
        const [popupDismissible, setPopupDismissible] = useState(true);

        useEffect(() => {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    getProjects().then((projects) => {
                        const p = [...projects, {id: 'new-project', label: 'New project'}]
                        setProjects(p);
                        setSelectedProject(p[0].label);
                        selectedProjectId.current = p[0].id;
                    }).catch((error) => {
                        console.error("Error fetching projects:", error);
                    });
                } else {
                    console.error("Unauthenticated");
                }
            });

            return () => unsubscribe();
        }, [auth]);

        useEffect(() => {
            if (!connected || robotConnection.current === null) {
                setSelectedTarget("Disconnected");
                setSelectedBranch("Disconnected");
                setSelectedCommit("");
            }
            else {
                alignSelectedProjectWithRobot().then(() => {
                    fetchData();
                });
            }
        }, [connected]);


        // Switches client or robot projects to make sure that they are in sync
        async function alignSelectedProjectWithRobot() {
            // The projects downloaded to the bot
            let botProjects = await robotConnection.current.connection.listProjects();
            // If the bot has the selected project, switch the project on the bot.
            // Calling switchProject will do nothing because it will assume that the selected project is already installed
            if (botProjects.includes(selectedProjectId.current)) {
                await robotConnection.current.connection.switchProject(selectedProjectId.current);
            }
            else {
                selectedProjectId.current = null; // stop switchProject from early returning

                // The project currently selected on the bot
                let selectedBotProject = await robotConnection.current.connection.getProject()
                let project = projects.find(p => p.id === selectedBotProject);
                // If we have the project selected on the bot, switch to it
                if (project) {
                    await switchProject(project.id)
                }
                // If we have any of the bots projects, switch to it
                else if ((project = projects.find(p => botProjects.includes(p.id)))) {
                    await switchProject(project.id)
                }
                // If we have any projects, install one on the bot
                else if (projects.length > 0) {
                    project = projects[0];
                    await switchProject(project.id);
                }
                else {
                    await switchProject("new-project");
                }
            }
        }

        // Fetches data about the currently selected project
        const fetchData = async () => {
            try {
                let branch = await robotConnection.current.connection.getBranch();
                setSelectedBranch(branch);

                let target = await robotConnection.current.connection.getTarget();
                setSelectedTarget(target.split("/").pop());

                let commit = await robotConnection.current.connection.getCommitHash();
                setSelectedCommit(commit);

                let branches = await robotConnection.current.connection.getBranches();
                setBranches(branches.split(",").map((val)=> { return {
                    label: val,
                    id: val
                }}));

                let targets = await robotConnection.current.connection.getTargets();
                setTargets(targets.split(",").map((val) => { return {
                    label: val.split("/").pop(),
                    id: val,
                }}));
                robotConnection.current.connection.listDevices().then(devices => {
                    setDevices(devices);
                }).catch((err) => {
                    console.error(err);
                })
            }
            catch(error) {
                console.error(error)
            }
        }


        async function handleNewProject(id) {
            getProjects().then((projects) => {
                setProjects([...projects, {id: 'new-project', label: 'New project'}]);
                switchProject(id);
            })
        }

        async function switchProject(id) {
            if (id === "new-project") {
                setShowNewProject(true);
                return;
            }

            if (selectedProjectId.current === id) {
                return;
            }
            setDisableMenus(true);
            const project = (await getDoc(doc(db, 'projects', id))).data();

            if (!connected) {
                setSelectedProject(project.name);
                selectedProjectId.current = id;
                setDisableMenus(false);
                return;
            }
            try {
                const installedProjects = await robotConnection.current.connection.listProjects();
                if (installedProjects.includes(id)) {
                    await robotConnection.current.connection.switchProject(id);
                    setSelectedProject(project.name);
                    selectedProjectId.current = id;
                    await fetchData();
                    setDisableMenus(false);
                    return;
                }
                setPopupMessage("Installing...");
                setPopupDismissible(false);
                setShowPopupMessage(true);
                if (project.git_token === "-1") {
                    project.git_token = window.prompt("Enter git deploy token")
                }
                robotConnection.current.connection.installProject(id, project.git_url, project.git_token).then((response) => {
                    if (response.split(",")[0] === "false") {
                        console.log(response.split(",")[1]);
                        setPopupMessage(response.split(",")[1]);
                        setShowPopupMessage(true);
                        setDisableMenus(false);
                        return;
                    }
                    setShowPopupMessage(false);
                    setPopupDismissible(true);
                    setSelectedProject(project.name);
                    selectedProjectId.current = id;
                    fetchData().then(() => {
                        setDisableMenus(false);
                    });
                }).catch((err) => {
                    console.error(err)
                    setPopupMessage(err)
                    setPopupDismissible(true);
                    setDisableMenus(false);
                })
            }
            catch(err) {
                console.error(err);
                setDisableMenus(false);
            }
        }


        const switchBranch = (id) => {
            setDisableMenus(true);
            robotConnection.current.connection.switchBranch(id).then(() => {
                fetchData().then(() => {
                    setDisableMenus(false);
                }).catch((error) => {
                    console.error(error);
                    robotConnection.current.connection.switchBranch(id).then(() => {
                        setDisableMenus(false);
                    }).catch((error) => {
                        console.error(error);
                    })
                })
            }).catch((error) => {
                console.error(error);
                setDisableMenus(false);
            })
        }

        const changeTarget = (id) => {
            setDisableMenus(true);
            robotConnection.current.connection.changeTarget(id).then(() => {
                setSelectedTarget(id.split("/").pop());
                setDisableMenus(false);
            })
        }

        function reloadPressed() {
            robotConnection.current.connection.pullChanges.then(() => {
                robotConnection.current.connection.getCommitHash().then((commitHash) => {
                    setSelectedCommit(commitHash);
                });
            }).catch((error) => {
                console.error(error);
            })
        }


        return (
            <>
                <NavBar>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <DropdownNavCell
                            content={(
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '24px', marginRight: '8px', color: '#61dafb' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                    </svg>
                                </span>
                                    {user.displayName}
                                </div>
                            )}
                            options={[
                                { id: 'signout', label: 'Sign out' },
                                { id: 'settings', label: "Account settings" }
                            ]}
                            onSelect={(id) => {
                                switch (id) {
                                    case 'signout':
                                        signOut(auth).then(() => {
                                            window.location = '/auth';
                                        });
                                        break;
                                    case 'settings':
                                        window.location = '/settings';
                                        break;
                                }
                            }}
                        />

                        <DropdownNavCell content={selectedProject || "Loading..."} options={projects} disabled={disableMenus} onSelect={switchProject} />
                        <DropdownNavCell
                            disabled={!connected || disableMenus}
                            content={(
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div style={{alignItems: 'center', display: 'flex'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 97 97"
                                             style={{marginRight: '10px'}}>
                                            <path fill="#F05133"
                                                  d="M92.71 44.408 52.591 4.291c-2.31-2.311-6.057-2.311-8.369 0l-8.33 8.332L46.459 23.19c2.456-.83 5.272-.273 7.229 1.685 1.969 1.97 2.521 4.81 1.67 7.275l10.186 10.185c2.465-.85 5.307-.3 7.275 1.671 2.75 2.75 2.75 7.206 0 9.958-2.752 2.751-7.208 2.751-9.961 0-2.068-2.07-2.58-5.11-1.531-7.658l-9.5-9.499v24.997c.67.332 1.303.774 1.861 1.332 2.75 2.75 2.75 7.206 0 9.959-2.75 2.749-7.209 2.749-9.957 0-2.75-2.754-2.75-7.21 0-9.959.68-.679 1.467-1.193 2.307-1.537v-25.23c-.84-.344-1.625-.853-2.307-1.537-2.083-2.082-2.584-5.14-1.516-7.698L31.798 16.715 4.288 44.222c-2.311 2.313-2.311 6.06 0 8.371l40.121 40.118c2.31 2.311 6.056 2.311 8.369 0L92.71 52.779c2.311-2.311 2.311-6.06 0-8.371z"/>
                                        </svg>
                                        {selectedBranch}
                                    </div>
                                    <span style={{
                                        fontSize: '10px',
                                        textAlign: 'right'

                                    }}>{selectedCommit}</span>
                                </div>
                            )}
                            options={branches}
                            searchable={true}
                            onSelect={switchBranch}
                        />

                        <DropdownNavCell
                            disabled={!connected || disableMenus}
                            content={(
                                <div style={{ alignItems: 'center', display: 'flex' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bullseye" viewBox="0 0 16 16" color="#fffd71">
                                        <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
                                        <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                    </svg>
                                    {selectedTarget}
                                </div>
                            )}
                            options={targets}
                            searchable={true}
                            onSelect={changeTarget}
                        />
                    </div>

                    <div style={{ alignItems: 'stretch', display: 'flex', justifyContent: 'space-between' }}>
                        <RobotConnection
                            connected={connected}
                            setConnected={setConnected}
                            ref={robotConnection}
                            reloadPressed={reloadPressed}
                        />
                    </div>
                </NavBar>
                <NewProject auth={auth} shown={showNewProject} setShown={setShowNewProject} callback={handleNewProject}></NewProject>
                <Popup shown={showInstallProject} onClose={() => setShowInstallProject(false)}>
                    <GlowButton onClick={() => {setShowInstallProject(false)}} text={"Cancel"}></GlowButton>
                    <GlowButton onClick={() => {setShowInstallProject(false)}} text={"Cancel"}></GlowButton>
                </Popup>
                <Popup shown={showPopupMessage} setShown={setShowPopupMessage} dismissible={popupDismissible} onClose={() => setShowPopupMessage(false)}>
                    <h3>{popupMessage}</h3>
                </Popup>
            </>


        );
    });

    export default ConsoleNavBar;
