import BluetoothUUIDs from "../../BluetoothUUIDs";
import {NetworkConnection} from "./NetworkConnection";

export class BLEConnection extends NetworkConnection {
    constructor() {
        super();
        this.device = null;
        this.deviceCharacteristic = null;
        this.communicationCharacteristic = null;
        this.executionCharacteristic = null;
        this.loggingCharacteristic = null;
        this.heartbeatCharacteristic = null;
        this.deviceListeners = new Map();
        this.messageQueue = Promise.resolve();
        this.onConnectionChange = null;
        this.onLogMessage = null;
    }

    setCallbacks(callbacks) {
        this.onConnectionChange = callbacks.onConnectionChange;
        this.onLogMessage = callbacks.onLogMessage;
    }

    async connect() {
        try {
            const device = await navigator.bluetooth.requestDevice({
                filters: [{ services: [BluetoothUUIDs.INTERACTIVE_SERVICE_UUID] }]
            });

            void this.onConnectionChange?.('connecting');
            const server = await device.gatt.connect();
            this.device = device;
            this.name = this.device.name;

            const interactiveService = await server.getPrimaryService(BluetoothUUIDs.INTERACTIVE_SERVICE_UUID);

            this.deviceCharacteristic = await interactiveService.getCharacteristic(BluetoothUUIDs.DEVICE_CHARACTERISTIC_UUID);
            this.communicationCharacteristic = await interactiveService.getCharacteristic(BluetoothUUIDs.COMMUNICATION_CHARACTERISTIC_UUID);
            this.executionCharacteristic = await interactiveService.getCharacteristic(BluetoothUUIDs.EXECUTION_CHARACTERISTIC_UUID);
            this.loggingCharacteristic = await interactiveService.getCharacteristic(BluetoothUUIDs.LOGGING_CHARACTERISTIC_UUID);
            this.heartbeatCharacteristic = await interactiveService.getCharacteristic(BluetoothUUIDs.HEARTBEAT_CHARACTERISTIC_UUID);

            await this.setupCharacteristicListeners();
            this.startHeartbeat();

            void this.onConnectionChange?.('connected');
            return this;
        } catch (error) {
            void this.onConnectionChange?.('disconnected');
            console.error("Bluetooth connection error: ", error);
            return Promise.reject(error);
        }
    }

    async setupCharacteristicListeners() {
        // Set up logging characteristic
        const receive_log = (event) => {
            const decoder = new TextDecoder();
            const [status, ...msgParts] = decoder.decode(event.target.value).split(",");
            const msg = msgParts.join(",");
            const msg_type = status === '0' ? "stdout" : "stderr";
            void this.onLogMessage?.(msg_type, msg);
        };
        await this.loggingCharacteristic.addEventListener('characteristicvaluechanged', receive_log);
        await this.loggingCharacteristic.startNotifications();

        // Set up device characteristic
        const decoder = new TextDecoder();
        let fullMessage = "";
        const receive_device_update = (event) => {
            const decodedMessage = decoder.decode(event.target.value);
            fullMessage += decodedMessage;
            if (decodedMessage.length < 250) {
                try {
                    let state = JSON.parse(fullMessage);
                    for (const device in state) {
                        for (const graphic in state[device]) {
                            state[device][graphic] = JSON.parse(state[device][graphic]);
                        }
                    }
                    const uuid = Object.keys(state)[0];
                    this.deviceListeners.get(uuid)(state[uuid]);
                } catch (error) {
                    console.error("Error parsing JSON:", error, fullMessage);
                }
                fullMessage = "";
            }
        };

        await this.deviceCharacteristic.addEventListener('characteristicvaluechanged', receive_device_update);
        await this.deviceCharacteristic.startNotifications();
    }

    startHeartbeat() {
        let last_receive = Date.now();
        this.heartbeatCharacteristic.startNotifications().then(() => {
            const intervalId = setInterval(async () => {
                if ((Date.now() - last_receive) / 1000 > 2.5) {
                    this.disconnect();
                    clearInterval(intervalId);
                } else {
                    this.sendMessageToCharacteristic(this.heartbeatCharacteristic, "").then(() => {
                        last_receive = Date.now();
                    });
                }
            }, 1000);
        });
    }

    disconnect() {
        if (this.device?.gatt.connected) {
            this.device.gatt.disconnect();
        }
        this.device = null;
        this.name = '';
        this.deviceCharacteristic = null;
        this.communicationCharacteristic = null;
        this.executionCharacteristic = null;
        this.loggingCharacteristic = null;
        this.heartbeatCharacteristic = null;
        this.deviceListeners = new Map();
        void this.onConnectionChange?.('disconnected');
    }

    async sendMessageToCharacteristic(characteristic, message) {
        let taskResolve, taskReject;
        const taskPromise = new Promise((resolve, reject) => {
            taskResolve = resolve;
            taskReject = reject;
        });

        this.messageQueue = this.messageQueue.then(() => {
            return new Promise((resolve, reject) => {
                if (!characteristic) {
                    taskReject("Not connected");
                    return resolve();
                }

                let full_message = "";
                let success;
                const onCharacteristicValueChanged = (event) => {
                    const decoder = new TextDecoder();
                    let msg;
                    let msg_len;
                    if (full_message === "") {
                        const [status, ...msgParts] = decoder.decode(event.target.value).split(',');
                        msg = msgParts.join(',');
                        msg_len = msg.length + 2;
                        success = status === '0';
                    } else {
                        msg = decoder.decode(event.target.value);
                        msg_len = msg.length;
                    }
                    full_message += msg;

                    if (msg_len < 250) {
                        characteristic.removeEventListener('characteristicvaluechanged', onCharacteristicValueChanged);
                        if (success) {
                            taskResolve(full_message);
                        } else {
                            taskReject(msg);
                        }
                        resolve();
                    }
                };

                characteristic.addEventListener('characteristicvaluechanged', onCharacteristicValueChanged);

                characteristic.startNotifications().then(async () => {
                    const encoder = new TextEncoder();
                    for (let i = 0; i < Math.ceil(message.length / 250); i++) {
                        const msgArray = encoder.encode(message.substring(i * 250, (i + 1) * 250));
                        await characteristic.writeValue(msgArray);
                    }
                    if (message.length % 250 === 0) {
                        await characteristic.writeValue(encoder.encode(""));
                    }
                }).catch(error => {
                    characteristic.removeEventListener('characteristicvaluechanged', onCharacteristicValueChanged);
                    taskReject(error);
                    resolve();
                });
            });
        }).catch((error) => {
            console.error(error);
            return Promise.resolve();
        });

        return taskPromise;
    }

    async executeCommand(command) {
        return this.sendMessageToCharacteristic(this.executionCharacteristic, command);
    }

    async sendCommunication(message) {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, message);
    }

    async get_ip() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-ip");
    }

    async listDevices() {
        if (!this.communicationCharacteristic) {
            return [];
        }
        try {
            const response = await this.sendCommunication("list-devices");
            let devices = response.split(",") || [];
            return devices.length === 0 || devices[0].length === 0 ? [] : devices;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async updateDeviceState(newState) {
        const string_state = JSON.stringify(newState);
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "set-state " + string_state);
    }

    async switchProject(projectId) {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, `switch-project ${projectId}`);
    }

    async listProjects() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "list-projects");
    }

    async getProject() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-project");
    }

    async getBranch() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-branch");
    }

    async getBranches() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-branches");
    }

    async getCommitHash() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-commit-hash");
    }

    async getTarget() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-target");
    }

    async getTargets() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-targets");
    }

    async getProjectDirectory() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-project-directory");
    }

    async switchBranch(branchName) {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, `switch-branch ${branchName}`);
    }

    async changeTarget(targetName) {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, `change-target ${targetName}`);
    }

    async pullChanges() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "pull-changes");
    }

    async installProject(projectId, url, token = null) {
        const command = token
            ? `install-project ${projectId} ${url} ${token}`
            : `install-project ${projectId} ${url}`;
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, command);
    }

    async executeTarget() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "execute-target");
    }

    async tinker() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "tinker");
    }

    async stopExecution() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "stop-execution");
    }


    async getState(deviceId) {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, `get-state ${deviceId}`);
    }

    async getStates() {
        return this.sendMessageToCharacteristic(this.communicationCharacteristic, "get-states");
    }

    isConnected() {
        return this.device?.gatt.connected || false;
    }

    isRealConnection() {
        return true;
    }
}