import React, {useEffect, useState, useRef} from 'react';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import ConsoleNavBar from "./ConsoleNavBar";
import Terminal from "../../ReactComponents/Terminal";
import {DeviceCellContainer} from "../../ReactComponents/DeviceCellContainer";

const auth = getAuth();

const Console = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const [selectedTab, setSelectedTab] = useState('Dashboard');
    const [selectedProject, setSelectedProject] = useState(null);
    const [devices, setDevices] = useState([]);

    const [connected, setConnected] = useState(false)
    const robotConnection = useRef(null);

    const navBarRef = useRef(null);
    const terminal = useRef(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user || (!user.emailVerified && user.providerData[0].providerId === "password")) {
                window.location = '/auth';
            }
            else {
                setUser(user);
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!robotConnection.current) return;

        const subscriber = (type, status) => {
            if (type === 'is-connected' && status === false) {
                setDevices([]);
            }
        };
        robotConnection.current.addSubscriber('console', subscriber);
    }, [robotConnection.current]);

    if (loading) {
        return <></>
    }
    else if (user) {
        return (
            <div style={{height: '100vh', overflow: 'hidden'}}>
                <ConsoleNavBar
                    ref={navBarRef}
                    user={user}
                    robotConnection={robotConnection}
                    connected={connected}
                    setConnected={setConnected}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    setDevices={setDevices}
                />

                <DeviceCellContainer robotConnection={robotConnection} devices={devices} />


                <Terminal
                    ref={terminal}
                    visibleTab={"execute"}
                    robotConnection={robotConnection}
                    selectedTab={selectedTab}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                />
            </div>
        );
    }
    else {
        return <script>window.location='/'</script>
    }
};

export default Console;
