export class NetworkConnection {
    constructor() {
        this.onConnectionChange = null;
        this.onLogMessage = null;
        this.deviceListeners = new Map();
        this.name = "";
    }

    /**
     * Set callback functions for connection events and logging
     * @param {Object} callbacks - Object containing callback functions
     * @param {Function} callbacks.onConnectionChange - Called when connection status changes
     * @param {Function} callbacks.onLogMessage - Called when receiving log messages
     */
    setCallbacks(callbacks) {
        this.onConnectionChange = callbacks.onConnectionChange;
        this.onLogMessage = callbacks.onLogMessage;
    }

    async get_ip() {
        throw new Error('Method get_ip() must be implemented');
    }

    /**
     * Switch to a different project
     * @param {string} projectId - ID of the project to switch to
     * @returns {Promise<void>}
     */
    async switchProject(projectId) {
        throw new Error('Method switchProject() must be implemented');
    }

    /**
     * List all available projects
     * @returns {Promise<Array>} Array of project information
     */
    async listProjects() {
        throw new Error('Method listProjects() must be implemented');
    }

    /**
     * Get information about current project
     * @returns {Promise<Object>} Project information
     */
    async getProject() {
        throw new Error('Method getProject() must be implemented');
    }

    /**
     * Get current branch information
     * @returns {Promise<Object>} Branch information
     */
    async getBranch() {
        throw new Error('Method getBranch() must be implemented');
    }

    /**
     * Get list of available branches
     * @returns {Promise<Array>} Array of branch information
     */
    async getBranches() {
        throw new Error('Method getBranches() must be implemented');
    }

    /**
     * Get current commit hash
     * @returns {Promise<string>} Commit hash
     */
    async getCommitHash() {
        throw new Error('Method getCommitHash() must be implemented');
    }

    /**
     * Get current target information
     * @returns {Promise<Object>} Target information
     */
    async getTarget() {
        throw new Error('Method getTarget() must be implemented');
    }

    /**
     * Get list of available targets
     * @returns {Promise<Array>} Array of target information
     */
    async getTargets() {
        throw new Error('Method getTargets() must be implemented');
    }

    /**
     * Get project directory path
     * @returns {Promise<string>} Project directory path
     */
    async getProjectDirectory() {
        throw new Error('Method getProjectDirectory() must be implemented');
    }

    /**
     * Switch to a different branch
     * @param {string} branchName - Name of the branch to switch to
     * @returns {Promise<void>}
     */
    async switchBranch(branchName) {
        throw new Error('Method switchBranch() must be implemented');
    }

    /**
     * Change the current target
     * @param {string} targetName - Name of the target to switch to
     * @returns {Promise<void>}
     */
    async changeTarget(targetName) {
        throw new Error('Method changeTarget() must be implemented');
    }

    /**
     * Pull latest changes from remote
     * @returns {Promise<void>}
     */
    async pullChanges() {
        throw new Error('Method pullChanges() must be implemented');
    }

    /**
     * Install project with given parameters
     * @param {string} projectId - ID of the project to install
     * @param {string} url - Project URL
     * @param {string|null} token - Optional authentication token
     * @returns {Promise<void>}
     */
    async installProject(projectId, url, token = null) {
        throw new Error('Method installProject() must be implemented');
    }

    /**
     * Execute current target
     * @returns {Promise<void>}
     */
    async executeTarget() {
        throw new Error('Method executeTarget() must be implemented');
    }

    /**
     * Start tinker mode
     * @returns {Promise<void>}
     */
    async tinker() {
        throw new Error('Method tinker() must be implemented');
    }

    /**
     * Stop current execution
     * @returns {Promise<void>}
     */
    async stopExecution() {
        throw new Error('Method stopExecution() must be implemented');
    }

    /**
     * List all available devices
     * @returns {Promise<Array>} Array of device information
     */
    async listDevices() {
        throw new Error('Method listDevices() must be implemented');
    }

    /**
     * Set device state
     * @param {Object} args - State arguments to set
     * @returns {Promise<void>}
     */
    async setState(args) {
        throw new Error('Method setState() must be implemented');
    }

    /**
     * Get state for specific device
     * @param {string} deviceId - ID of the device
     * @returns {Promise<Object>} Device state
     */
    async getState(deviceId) {
        throw new Error('Method getState() must be implemented');
    }

    /**
     * Get states for all devices
     * @returns {Promise<Object>} Map of device states
     */
    async getStates() {
        throw new Error('Method getStates() must be implemented');
    }

    async connect() {
        throw new Error('Method connect() must be implemented');
    }

    disconnect() {
        throw new Error('Method disconnect() must be implemented');
    }

    async executeCommand(command) {
        throw new Error('Method executeCommand() must be implemented');
    }

    async sendCommunication(message) {
        throw new Error('Method sendCommunication() must be implemented');
    }

    async updateDeviceState(newState) {
        throw new Error('Method updateDeviceState() must be implemented');
    }

    isConnected() {
        return false;
    }
    isRealConnection() {
        return false;
    }
}