import React, {useState, useEffect} from 'react';
import GlowField from "./GlowField";
import GlowCheckbox from "./GlowCheckbox";
import GlowButton from "./GlowButton";
import Popup from "./Popup";
import {getFirestore, addDoc, collection} from "firebase/firestore";

const NewProject = ({auth, shown, setShown, callback}) => {


    const [projectName, setProjectName] = useState("");
    const [remoteURL, setRemoteURL] = useState("");
    const [deployKey, setDeployKey] = useState("");
    const [storeKey, setStoreKey] = useState(false);

    const [errorShown, setErrorShown] = useState(false);
    const [error, setError] = useState("");
    const [showConvertButton, setShowConvertButton] = useState(false);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setShown(false);
            }
        };
        document.addEventListener('keydown', handleEscape);

        return () => document.removeEventListener('keydown', handleEscape);
    }, [setShown]);

    if (!shown) return null;

    const popupOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Slightly darkened background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    };

    const popupContentStyle = {
        position: 'relative',
        backgroundColor: '#222',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px #00f0ff', // Less aggressive outer glow
        color: '#fff',
        maxWidth: '400px',
        width: '90%',
        minHeight: '200px',
        textAlign: 'center',
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        left: '10px',
        background: 'none',
        border: 'none',
        color: '#fff',
        fontSize: '20px',
        cursor: 'pointer',
    };

    async function createProject() {
        if (!projectName) {
            setError("Project name is required")
            setErrorShown(true);
        }
        if (!remoteURL) {
            setError("Remote URL is required")
            setErrorShown(true);
        }
        let validSSH = /^(git@|ssh:\/\/)/;

        // if (!validSSH.test(remoteURL)) {
        //     try {
        //         const converted = convertUrl(remoteURL);
        //         setShowConvertButton(true);
        //         setError(`HTTP is not supported. Would you like to automatically convert to\n${converted}`);
        //         setErrorShown(true);
        //         return;
        //     } catch (err) {
        //         setError("Please enter a valid ssh clone url.");
        //         setErrorShown(true);
        //         return;
        //     }
        // }
        let db = getFirestore();

        let ref = await addDoc(collection(db, "projects"), {
            git_token: storeKey ? deployKey: null,
            git_url: remoteURL,
            name: projectName,
            organization: auth.currentUser.email.split("@")[1],
        });
        callback(ref.id);
        setShown(false);
    }

    function runConvert() {
        try {
            setRemoteURL(convertUrl(remoteURL));
            console.log(remoteURL);
        }
        catch(err) {
            console.error(err)
        }
        setErrorShown(false);
    }

    function convertUrl(url) {
        let pattern = /^(?:https?:\/\/)?([^\/]+)\/(.+)/;
        let match = url.match(pattern);

        if (match) {
            let domain = match[1];
            let repoPath = match[2];
            if (!repoPath.endsWith(".git")) {
                repoPath += ".git";
            }
            return `git@${domain}:${repoPath}`;
        }
        throw new Error("Unsupported URL format. Please provide a valid URL.");
    }



    return (
        <div style={popupOverlayStyle}>
            <div style={popupContentStyle}>
                <button style={closeButtonStyle} onClick={() => setShown(false)}>X</button>
                <GlowField placeholder={"Project name"} value={projectName} onChange={setProjectName}></GlowField>
                <GlowField placeholder={"Remote URL"} value={remoteURL} onChange={setRemoteURL}></GlowField>
                <GlowField placeholder={"Deploy key (optional)"} value={deployKey} onChange={setDeployKey}></GlowField>
                <GlowCheckbox label={"Store key on server"} checked={storeKey} onChange={setStoreKey}></GlowCheckbox>
                <p style={{fontSize: '12px'}}>You can optionally specify a deploy key to allow the robot to read from private repos. If you choose not to store it on our servers, you will need to re-enter it to setup a new robot.</p>
                <GlowButton text={"Create"} onClick={createProject}></GlowButton>
            </div>
            <Popup shown={errorShown} onClose={() => {setErrorShown(false); setShowConvertButton(false)}}>
                <h3>{error}</h3>
                {showConvertButton && (
                    <GlowButton text={"Convert to ssh"} onClick={runConvert}></GlowButton>
                )}

            </Popup>
        </div>
    );
};

export default NewProject;
