import React from 'react';

const LoadingSpinner = ({ dimension }) => {
    const containerStyle = {
        '--uib-size': `${dimension}px`,
        '--uib-color': '#61dafb',
        '--uib-speed': '1.2s',
        '--uib-bg-opacity': '0.1',
    };

    return (
        <div>
            <svg
                className="container"
                viewBox="0 0 35 35"
                height={dimension}
                width={dimension}
                style={containerStyle}
            >
                <rect
                    className="track"
                    x="2.5"
                    y="2.5"
                    fill="none"
                    strokeWidth="5px"
                    width="32.5"
                    height="32.5"
                />
                <rect
                    className="car"
                    x="2.5"
                    y="2.5"
                    fill="none"
                    strokeWidth="5px"
                    width="32.5"
                    height="32.5"
                    pathLength="100"
                />
            </svg>
            <style>
                {`
                .container {
                    --uib-size: 35px;
                    --uib-color: black;
                    --uib-speed: 1.2s;
                    --uib-bg-opacity: 0.1;
                    height: var(--uib-size);
                    width: var(--uib-size);
                    transform-origin: center;
                    will-change: transform;
                    overflow: visible;
                }

                .car {
                    fill: none;
                    stroke: var(--uib-color);
                    stroke-dasharray: 25, 75;
                    stroke-dashoffset: 0;
                    animation: travel var(--uib-speed) linear infinite;
                    will-change: stroke-dasharray, stroke-dashoffset;
                    transition: stroke 0.5s ease;
                }

                .track {
                    fill: none;
                    stroke: var(--uib-color);
                    opacity: var(--uib-bg-opacity);
                    transition: stroke 0.5s ease;
                }

                @keyframes travel {
                    0% {
                        stroke-dashoffset: 0;
                    }

                    100% {
                        stroke-dashoffset: -100;
                    }
                }
                `}
            </style>
        </div>
    );
};

export default LoadingSpinner;
